import React from 'react';
import './App.css';
import { getFestivals, Festival, REGIONS_NETHERLANDS } from './festivals';
import { getWeekday } from './utils';

const COUNTRIES = ["The Netherlands"];
const REGIONS = REGIONS_NETHERLANDS;
const MUSIC_CATEGORIES = ["Alle muzieksoorten", "Techno"];

const FESTIVALS = ["John", "Test"];

interface SelectMenuProps {
  className?: string;
  onChange?: (value: string) => void;
  values: Array<string>;
}

function SelectMenu(props: SelectMenuProps) {

  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    props.onChange?.(event.target.value)
  }

  return (
    <select className={props.className} onChange={onChange}>
      {props.values.map(country => 
        <option>
          {country}
        </option>
      )}
    </select>
  );
}

function dateToString(date: Date): string {
  return date.getFullYear() + "-" 
  + date.getMonth().toString().padStart(2, '0') + "-"
  + date.getDate().toString().padStart(2, '0');
}

function stringToDate(date: string): Date {
  const splitted = date.split("-");
  return new Date(parseInt(splitted[0]), parseInt(splitted[1])-1, parseInt(splitted[2])-1);
}

function App() {
  const [date, setDate] = React.useState(dateToString(new Date()));
  const [region, setRegion] = React.useState("Alle provincies");
  const [musicCategory, setMusicCategory] = React.useState("Alle muzieksoorten");
  const [festivals, setFestivals] = React.useState<Array<Festival>>(getFestivals(date, "The Netherlands", region, musicCategory));

  React.useEffect(() => {
    setFestivals(getFestivals(date, "The Netherlands", region, musicCategory));
    console.log(`Filtering for: ${date}, ${region}, ${musicCategory}`);
  }, [date, region, musicCategory])

  const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDate(event.target.value);
  }

  const handleRegionChange = (value: string) => {
    setRegion(value);
  }

  const handleMusicCategoryChange = (value: string) => {
    setMusicCategory(value);
  }

  return (
    <div className="app">
      <div className="header">
        <div className="title-wrapper">
          <div className="title-spacer"></div>
          <div className="title">Vind jouw volgende festival</div>
          <div className="subtitle">Je volgende rave in een paar clicks...</div>
        </div>
      </div>
      <div className="main">
        <div className="searchbox">
          <input className="main-select" type="date" onChange={handleDateChange} value={date} />
          <SelectMenu className="main-select" onChange={handleRegionChange} values={REGIONS} />
          <SelectMenu className="main-select" onChange={handleMusicCategoryChange} values={MUSIC_CATEGORIES} />
        </div>
        <div className="search-results">
          {festivals.map(f => {
            return (
            <div style={{border: "1px solid black", display: "flex", flexDirection: "column", marginTop: "20px", padding: "5px"}}>
              <div style={{}}>
                <h3 style={{margin: 0, padding: 0}}>{f.dates[0]} ({getWeekday(f.dates[0])})</h3>
              </div>
              <div style={{display: "flex"}}>
                <div>
                  <img src={f.thumbnailURL} width="200px" height="200px" />
                </div>
                <div>
                  <h1 style={{padding: 0, margin: 0}}>{f.name}</h1>
                  <h3 style={{padding: 0, margin: 0}}>{f.location.venue}, {f.location.city}</h3>
                </div>
              </div>
              </div>
            );
          })}
        </div>
      </div>
   </div>
  );
}

export default App;
